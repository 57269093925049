import loadJSON from "./loadJSON";

const publicApi = loadJSON(`${process.env.PUBLIC_URL}/api/api.json`);
const BASE_URL = publicApi.BASE_URL;

//UserManual
const LEXUS_USER_MANUAL = publicApi.LEXUS_USER_MANUAL;
const TOYOTA_USER_MANUAL = publicApi.TOYOTA_USER_MANUAL;

// Login
const CLIENT_ID = publicApi.CLIENT_ID;
const CLIENT_SECRET = publicApi.CLIENT_SECRET;
const GRANT_TYPE = publicApi.GRANT_TYPE;
const LOGIN_PROFILE = BASE_URL + publicApi.LOGIN_PROFILE;
const LOGIN_URL = publicApi.LOGIN_URL;
const EASY_PAY_URL = publicApi.EASY_PAY_URL;

// Event Manager
const EVENT_MANAGER_URL = publicApi.EVENT_MANAGER_URL;

// BFF
const DASHBOARD_BFF_API = BASE_URL + publicApi.DASHBOARD_BFF_API;

// LexusDomain
const LEXUS_DOMAIN = publicApi.LEXUS_DOMAIN

export {
    CLIENT_ID, CLIENT_SECRET, EASY_PAY_URL, GRANT_TYPE, LOGIN_PROFILE, LOGIN_URL, EVENT_MANAGER_URL, DASHBOARD_BFF_API, BASE_URL, LEXUS_DOMAIN, TOYOTA_USER_MANUAL, LEXUS_USER_MANUAL
};