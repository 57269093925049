import { initReactI18next } from "react-i18next";
import PublishSubscribe from "publish-subscribe-js";
import {useEffect, useState} from "react";
import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import itIT from "antd/lib/locale/it_IT";
import enUS from "antd/lib/locale/en_US";
import {topic} from "../constants/constants";

const antdLocales = {
    it : itIT,
    en : enUS
};

i18n.initI18n = function() {
    const instance = i18n.createInstance();

    instance.numbers = {minimumFractionDigits:2};
    instance.getDateFormatPattern = getDateFormatPattern;
    instance.getDateTimeFormatPattern = getDateTimeFormatPattern;
    instance.getMonthYearFormatPattern = getMonthYearFormatPattern;
    instance.on("languageChanged", (lng) => {
        instance.dateFormatPattern = instance.getDateFormatPattern(lng);
        instance.dateTimeFormatPattern = instance.getDateTimeFormatPattern(lng);
        instance.monthYearFormatPattern = instance.getMonthYearFormatPattern(lng);
        instance.numbers.formatter = new Intl.NumberFormat(lng, {
            minimumFractionDigits: instance.numbers.minimumFractionDigits,
            maximumFractionDigits: instance.numbers.minimumFractionDigits
        });
        instance.antdLocale = antdLocales[lng];
        PublishSubscribe.publish(topic.i18n.languageChange, instance.antdLocale);
    });
    instance.on("initialized", (options) => {
        PublishSubscribe.publish(topic.i18n.initialized, instance);
    });

    instance.use(initReactI18next)
            .use(LanguageDetector)
            .use(resourcesToBackend((language, namespace, callback) => {
                import(`./locales/${language}/${namespace}.json5`)
                      .then((resources) => {
                          callback(null, resources.default)
                      })
                      .catch((error) => {
                          callback(error, null)
                      });
            }))
            .init({
                // Allowed languages
                supportedLngs: ["it", "en"],
                fallbackLng: "it",

                preload: ["it"],

                //debug: true,

                // have a common namespace used around the full app
                ns: ["labels", "messages", "states"],
                defaultNS: "labels",

                //keySeparator: false, // we use content as keys

                interpolation: {
                    escapeValue: false, // not needed for react!!
                    formatSeparator: ","
                },

                react: {
                    wait: true,
                    //useSuspense: false
                }
            }).then(() => {});

    return instance;
}

function getDateTimeFormatPattern(locale) {
    // expected style = year: 2010, month: 12, day: 31
    let options = { year: "numeric", month: "2-digit", day: "2-digit",
                    hour: "2-digit", minute: "2-digit", second: "2-digit"};

    return getTemporalFormatPattern(locale, options);
}

function getDateFormatPattern(locale) {
    // expected style = year: 2010, month: 12, day: 31
    let options = { year: "numeric", month: "2-digit", day: "2-digit" };

    return getTemporalFormatPattern(locale, options);
}

function getMonthYearFormatPattern(locale) {
    // expected style = year: 2010, month: 12
    let options = { year: "numeric", month: "2-digit" };

    return getTemporalFormatPattern(locale, options);
}

function getTemporalFormatPattern(locale, options) {
    // formatToParts() returns array of objects breaking down the locales dateformat
    // [
    //  {type: "month", value: "03"},
    //  {type: "literal", value: "/"},
    //  {type: "day", value: "30"},
    //  {type: "literal", value: "/"},
    //  {type: "year", value: "2021"},
    // ]
    let dateFormatParts = new Intl.DateTimeFormat(locale, options).formatToParts();

    return dateFormatParts.map(function(part) {
        switch(part.type) {
            case "month":
                return "MM";
            case "day":
                return "DD";
            case "year":
                return "YYYY";
            case "hour":
                return "HH";
            case "minute":
                return "mm";
            case "second":
                return "ss";
            default:
                return part.value;
        }
    }).join("");
}


const useEnhancedTranslation = function(i18nLib) {
    let [_dateFormatPattern, set_dateFormatPattern] = useState();
    let [_dateTimeFormatPattern, set_dateTimeFormatPattern] = useState();
    let [_monthYearFormatPattern, set_monthYearFormatPattern] = useState();
    let [_numbersFormatter, set_numbersFormatter] = useState();
    let [_antdLocale, set_antdLocale] = useState();

    useEffect(() => {});

    const changeLanguage = function(lng, callback) {
        return i18nLib.changeLanguage(lng, callback).then(() => {
            set_dateFormatPattern(i18nLib.dateFormatPattern);
            set_dateTimeFormatPattern(i18nLib.dateTimeFormatPattern);
            set_monthYearFormatPattern(i18nLib.monthYearFormatPattern);
            set_numbersFormatter(i18nLib.numbers.formatter);
            set_antdLocale(i18nLib.antdLocale);
        });
    }

    return new Proxy(i18nLib, {
        get(obj, prop, value) {
            let val = Reflect.get(...arguments);
            if (typeof val === "function" && prop === "changeLanguage") {
                val = changeLanguage;
            }
            return val;
        },
        set(obj, prop, value) {
            return Reflect.set(...arguments);
        }
    });
};


export default i18n;
export {useEnhancedTranslation};