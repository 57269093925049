import React, {useContext} from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import {withTranslation} from "react-i18next";
import { Menu } from "antd";
import Icon from "@ant-design/icons";
import {AppContext} from "../App";
import {menuRoutes} from "../../routing/menuRoutes";
import { toyotaOrLexusElement } from "../../utils/CssHandler"

const { SubMenu } = Menu;

const MenuContainer = () => {
  const appData = useContext(AppContext);

  return (
    <Menu theme="dark" mode="inline" selectedKeys={[useHistory().location.pathname]} className = {toyotaOrLexusElement("ant-menu")}>
      {appData.menuRoutes.map((e, i) => {
        return (
          <SubMenu 
            key={"menu_1_" + i}
            title={
              <Link to={e.path}>
                {e.icon && <Icon type={e.icon} style={{ marginRight: 10 }} />}
                {e.name}
              </Link>
            }>
            {e.routes.map((f, j) => {
              if(!f.sub){
                return (
                  <Menu.Item key={f.path}>
                    <Link to={f.path} id={"submenu_" + f.id}>{f.name}</Link>
                  </Menu.Item>
                )
              }else{
                return (
                  <SubMenu key={"menu_" + j} 
                  title={
                    <Link to={f.path}>
                      {f.icon && <Icon type={f.icon} style={{ marginRight: 10 }} />}
                      {f.name}
                    </Link>
                  }>
                  {
                    f.sub.map((x, c) => {
                      return(
                        <Menu.Item key={x.path}>
                          <Link to={x.path} id={"submenu_" + x.id}>{x.name}</Link>
                        </Menu.Item>
                      )
                    })
                  }
                  </SubMenu>
                )
              }
            })}
          </SubMenu>
        )
      })}
    </Menu>
  );
};

export default withRouter(withTranslation('labels', { wait: true })(MenuContainer));