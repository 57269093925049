import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { logout } from '../../../components/App';
import {BASE_URL} from "../../../utils/api"

type CustomAxiosInstance = AxiosInstance & {
  setDefaults: (config: Partial<AxiosRequestConfig>) => void
}
interface Config extends AxiosRequestConfig {
  retry: boolean
}

interface ExtendedError extends AxiosError {
  config: Config
}
const statuses = [403]
const responseInterceptor = (response: AxiosResponse) => response
const requestInterceptor = async (config: AxiosRequestConfig) => {
  config.baseURL = BASE_URL

  return config
}
const simpleErrorInterceptor = (error: ExtendedError) => {
  return Promise.reject(error)
}
const errorInterceptor = (error: ExtendedError) => {
  const originalRequest = error.config
  const status = error.response?.status || 0

  if (statuses.includes(status)) {
    resetSignIn()
    return Promise.reject(error)
  }
  return Promise.reject(error)
}

export const dashboardAxiosInstance = axios.create();

dashboardAxiosInstance.interceptors.request.use(requestInterceptor, errorInterceptor)
dashboardAxiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor)

export const keycloakInstance = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

keycloakInstance.interceptors.request.use(
  simpleErrorInterceptor,
)

dashboardAxiosInstance.setDefaults = config =>
  Object.assign(dashboardAxiosInstance.defaults, {
    ...dashboardAxiosInstance.defaults,
    ...config,
  })

function resetSignIn() {
  alert("Sessione scaduta")
  logout();
}
