
const APP_BASE_URL = "/demetra/";

export const routesPaths = {
    "utenze" : APP_BASE_URL + "admin/viewUsers/",
    "admin" : APP_BASE_URL + "admin/",
    "addDealer" : APP_BASE_URL + "admin/aggiungiUtente",
    "addFiliale" : APP_BASE_URL + "admin/aggiungiFiliale",
    "uploadUserManual" : APP_BASE_URL + "admin/uploadUserManual",
    "uploadODL" : APP_BASE_URL + "upload/ODL",
    "performance" : APP_BASE_URL + "performance",
    "bflowsConfiguration" : APP_BASE_URL + "bflows/configuration",
    "bflowsDashboard" : APP_BASE_URL + "bflows/dashboard",
    "bflowsInvoices" : APP_BASE_URL + "bflows/invoices",
    "bflowsTransaction" : APP_BASE_URL + "bflows/transaction",
    "cashbackConfiguration" : APP_BASE_URL + "cashback/configuration",
    "cashbackDashboard" : APP_BASE_URL + "cashback/dashboard",
    "creditCardConfiguration" : APP_BASE_URL + "creditCard/configuration",
    "creditCardDashboard" : APP_BASE_URL + "creditCard/dashboard",
    "creditCardTransaction" : APP_BASE_URL + "creditCard/transaction",
    "customersDashboard" : APP_BASE_URL + "customers/dashboard",
    "customersDisputeManagement" : APP_BASE_URL + "customers/disputeManagement",
    "customersUsersAndMerchants" : APP_BASE_URL + "customers/usersAndMerchants",
    "eventManagerEvent" : APP_BASE_URL + "eventManager/event",
    "eventManagerEventConfiguration" : APP_BASE_URL + "eventManager/eventConfiguration",
    "eventManagerEventSystem" : APP_BASE_URL + "eventManager/eventSystem",
    "loyaltyAndVoucherConfiguration" : APP_BASE_URL + "loyaltyAndVoucher/configuration",
    "loyaltyAndVoucherDashboard" : APP_BASE_URL + "loyaltyAndVoucher/dashboard",
    "notificationsConfiguration" : APP_BASE_URL + "notifications/configuration",
    "notificationsDashboard" : APP_BASE_URL + "notifications/dashboard",
    "paymentGatewayAdmin" : APP_BASE_URL + "paymentGateway/admin",
    "paymentGatewayDashboard" : APP_BASE_URL + "paymentGateway/dashboard",
    "paymentGatewayDefinitionRules" : APP_BASE_URL + "paymentGateway/definitionRules",
    "paymentGatewayPaymentTools" : APP_BASE_URL + "paymentGateway/paymentTools",
    "paymentGatewayPaymentsExecutions" : APP_BASE_URL + "paymentGateway/payments/excecutions",
    "paymentGatewayPaymentsRequests" : APP_BASE_URL + "paymentGateway/payments/requests",
    "paymentGatewayPaymentsReconciliation" : APP_BASE_URL + "paymentGateway/payments/riconciliation",
    "paymentGatewayProducts" : APP_BASE_URL + "paymentGateway/products",
    "paymentGatewaySalesChannels" : APP_BASE_URL + "paymentGateway/salesChannels",
    "paymentHubDashboard" : APP_BASE_URL + "paymentHub/dashboard",
    "paymentHubEmandate" : APP_BASE_URL + "paymentHub/emandate",
    "paymentHubIncoming" : APP_BASE_URL + "paymentHub/incoming",
    "paymentHubOutgoing" : APP_BASE_URL + "paymentHub/outgoing",
    "paymentHubTransaction" : APP_BASE_URL + "paymentHub/transaction",
    "paymentHubWesternUnion" : APP_BASE_URL + "paymentHub/westernUnion",
    "scheduleJobsDashboard" : APP_BASE_URL + "scheduleJobs/dashboard",
    "systemDashboard" : APP_BASE_URL + "system/dashboard",
    "transactionsDashboard" : APP_BASE_URL + "transactions/dashboard",
    "transactionsP2P" : APP_BASE_URL + "transactions/p2p",
    "transactionsPurchase" : APP_BASE_URL + "transactions/purchase",
    "usersDashboard" : APP_BASE_URL + "users/dashboard",
    "usersStatistics" : APP_BASE_URL + "statistics/users",
    "ticket": APP_BASE_URL + "ticket",
    "viewPayment": APP_BASE_URL + "payment/view"
};
