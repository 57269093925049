import React, { useContext, useEffect } from "react";
import MenuContainer from "../../components/MenuContainer/index.js";
import { REDIRECT_LOGO } from "../../utils/redirectPaths";
import { Link } from "react-router-dom";
import { Layout, Avatar, Col, Row, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AppContext, logout } from "../App";
import "../../css/global.scss"
import { logoHandler, toyotaOrLexusElement, selectCssLexusId, setTitleAndFavicon, isLexusUrl } from "../../utils/CssHandler.js"
import { BASE_URL, DASHBOARD_BFF_API } from "../../utils/api.js";
import axios from "axios";

const { Header, Sider, Content } = Layout;

const MainLayout = props => {
  const { children } = props;
  const context = useContext(AppContext);

  const showEmailInAvatarBox = false;

  var logo = logoHandler();

  async function getUserManualPdfByBrand() {
    let brand = "TOYOTA";
    if (isLexusUrl()) {
      brand = "LEXUS";
    }
    await axios.get(DASHBOARD_BFF_API + `/documents/get-user-manual-document/${brand}`, {
      params: {
      }
    }).then((response) => {
      if (response.data) {
        const userManual = response.data
        if (userManual.docBase64 != undefined) {
          const blob = base64ToBlob(
            response.data.docBase64,
            'pdf',
          )
          const blobURL = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.setAttribute('download', 'manuale-utente' + '.pdf')
          link.href = blobURL
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          console.log('File non esistente')
        }
      }
    }
    ).catch((error) => {
      console.log("ERROR" + error);
    }
    );
  };

  function base64ToBlob(base64, contentType) {
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return new Blob([bytes], {
      type: contentType === 'IMAGE' ? 'image/jpg' : 'application/pdf',
    })
  }

  useEffect(() => {
  })

  const accountMenu = (
    <Menu>
      <Menu.Item key="logout">
        <div onClick={logout}>Logout</div>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="MainLayout">
      <Header className={toyotaOrLexusElement("ant-layout-header")}
        style={{
          background: "#fff",
          borderBottom: "1px solid #282830",
          position: "fixed",
          padding: "0 25px 0 25px",
          left: 0,
          top: 0,
          right: 0,
          height: 90,
          zIndex: 999
        }}
      >
        <Link to={REDIRECT_LOGO}>
          <img src={logo} alt="TOYOTA" style={{ marginTop: "15px" }} width="270" height="49" />
        </Link>
        <div style={{ float: "right", marginTop: "6px" }}>
          <a target="_blank" onClick={getUserManualPdfByBrand} style={{ marginRight: "20px" }} className={toyotaOrLexusElement("a-link")}>Manuale Utente</a>
          <Dropdown overlay={accountMenu} placement="bottomCenter">
            <Avatar style={{ backgroundColor: "#001529", cursor: "pointer" }} icon={<UserOutlined />} />
          </Dropdown>
          <div style={{ marginTop: "22px", float: "right", height: "30px", lineHeight: "8px", padding: "0", marginLeft: "10px" }}>
            <p><b>{context.dealerName}</b></p>
            <p>{context.user}</p>
          </div>
        </div>
      </Header>
      <Layout>
        <Sider
          width={220}
          className={toyotaOrLexusElement("ant-menu")}
          id={selectCssLexusId("colorFontSider")}
          style={{
            borderRadius: "0px 2rem 0px 0px",
            overflow: "auto",
            left: 0,
            top: 90,
            marginRight: 5
          }}
        >
          <MenuContainer />
        </Sider>
        <Layout style={{ padding: "0 5px", background: "#fff" }}>
          <Content
            style={{
              background: "#fff",
              marginTop: 100,
              padding: 40,
              minHeight: "calc(100vh - 64px)",
              minWidth: "calc(100vh - 64px)"
            }}>
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default MainLayout;
