import React, {useState} from "react";
import "./LoginPage.scss";
import axios from 'axios';
import qs from 'qs';
import {LOGIN_PROFILE, LOGIN_URL, GRANT_TYPE, CLIENT_ID, CLIENT_SECRET} from '../utils/api';
import {Divider} from 'antd';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {routesPaths} from "../routing/routesPaths";
import { dashboardAxiosInstance } from "../custom/components/axios/AxiosInstance";
import { DASHBOARD_BFF_API } from "../utils/api";
import { logoHandler, colorSetter } from "../utils/CssHandler"


const LoginPage = (props) => {
    const {t} = props;
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [setUpPassword , setSetUpPassword] = useState(false);
    const [repeatPassword , setRepeatPassword] = useState();
    var logo = logoHandler();

    const loginAddresses = {
        loginUrl: LOGIN_URL,
        loginProfile: LOGIN_PROFILE
    };

    const loginData = {
        grant_type: GRANT_TYPE,
        username: username,
        password: password,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET
    };

    async function loginAuth() {
        setLoading(true);

        if (!username || !password) {
            alert(t("error.fillInLoginFormFirst", {ns: "messages"}))
        } else {
            await axios({
                url: loginAddresses.loginUrl,
                method: "POST",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                data: qs.stringify(loginData)
            }).then((response) => {
                console.log("qui")
                let jwt = response.data.access_token;
                sessionStorage.setItem("authToken", jwt);
                retrieveProfiles(jwt);
            }).catch((error) => {
                if(error.response.data.error_description === "Account is not fully set up" && error.response.status === 400){
                    alert("Si prega di reimpostare la password");
                    setSetUpPassword(true);
                }else {
                
                    const s401 = error.response && error.response.status === 401 && 401;
                    if (s401) {
                        alert(t("error.authFailed", {ns: "messages"}));
                    } else {
                        alert(t("error.serviceUnavailable", {ns: "messages"}) + ": " + error.response);
                    }
                }
            })
        }
        setLoading(false);
    }

    async function retrieveProfiles(jwt) {
        await dashboardAxiosInstance.get(loginAddresses.loginProfile, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        }).then((response) => {  
            sessionStorage.setItem("enabledModules", response.data.modulesEnabled);
            sessionStorage.setItem("enabledFunctions", response.data.functionsEnabled);
            sessionStorage.setItem("username", response.data.username);
            sessionStorage.setItem("email", response.data.email);
            sessionStorage.setItem("dealerCode", response.data.dealerCode);
            sessionStorage.setItem("dealerName", response.data.dealerName);

            var dealership;
            var dealerAddresses = [];
            var concessionarie = [];
            dashboardAxiosInstance.get(DASHBOARD_BFF_API + "/dealeship/", {
                headers: { "authorization": `Bearer ${sessionStorage.getItem("authToken")}` },
                params: {
                  appId: "TOYOTA",
                  dealerCode: sessionStorage.getItem("dealerCode") === "ALL" ? null : sessionStorage.getItem("dealerCode")
                }
            }).then((response) => {
                dealership = response.data;
                dealerAddresses.push.apply(dealerAddresses, [...new Set(response.data.map(((element) => element.address)))].sort())
                concessionarie.push.apply(concessionarie, [...new Set(response.data.map(((element) => element.name)))].sort())

                sessionStorage.setItem("dealership", JSON.stringify(dealership))
                sessionStorage.setItem("dealerAddresses", JSON.stringify(dealerAddresses))
                sessionStorage.setItem("concessionarie", JSON.stringify(concessionarie))

                window.location.replace(routesPaths.transactionsDashboard);

            }).catch((error) => {
                console.log(error)
            });



        }).catch((error) => {
            alert(t("error.errCallingProfileService", {ns: "messages"}));
        });

    }

    function setUpNewPassword(){
        if(password === repeatPassword) {
            setLoading(true);
            var axios = require('axios');

            const encodedPassword = Buffer.from(password).toString('base64');

            var config = {
              method: 'put',
              url: `${DASHBOARD_BFF_API}/security/changePassword?username=${username}&password=${encodedPassword}`,
              headers: {
                'accept': 'application/json'
              }
            };
            
            axios(config).then(function (response) {
              setSetUpPassword(false);
              alert("Aggiornamento password avvenuto con successo");
              setLoading(false);
            }).catch(function (error) {
              console.log(error);
              setLoading(false);
            });
        }else{
            alert("Hai inserito password diverse!");
        }
    }

    function handleFormInputs(e, id) {
        if (id === "username") {
            setUsername(e.target.value);
        }
        if (id === "password") {
            setPassword(e.target.value);
        }
           if (id === "repeatPassword") {
            setRepeatPassword(e.target.value);
        }
    }

    function handleEnterKeyPress(event) {
        if (event.key === "Enter") {
            loginAuth();
        }
    }

    return (

        <div className="login-page">

            {!setUpPassword &&
            <div className="login-page_form-container">
                <img width="350px" height="64px" src={logo} alt="toyota-logo"/>

                <Grid container
                      style={{marginTop: "20px"}}
                      spacing={1}
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center">
                    <Grid container item xs={12}>
                        <TextField fullWidth
                                   id="outlined"
                                   label="Username"
                                   variant="outlined"
                                   onChange={(e) => handleFormInputs(e, "username")}
                                   onKeyPress={handleEnterKeyPress} />
                    </Grid>
                    <Grid container item xs={12}>
                        <TextField fullWidth
                                   type="password"
                                   id="outlined-password-input"
                                   label="Password"
                                   variant="outlined"
                                   onChange={(e) => handleFormInputs(e, "password")}
                                   onKeyPress={handleEnterKeyPress} />
                    </Grid>
                    <Grid container item xs={12}>
                        <Button variant="contained"
                                style={{backgroundColor: colorSetter()}}
                                loading={loading.toString()}
                                onClick={loginAuth}
                                fullWidth>
                            LOGIN
                        </Button>
                    </Grid>
                </Grid>
            </div>
            }
            {setUpPassword &&
                <div className="login-page_form-container">
                    <img width="350px" height="64px" src={logo} alt="toyota-logo"/>

                    <Grid container
                    style={{marginTop: "20px",
                            backgroundColor: "#FFFFFF", 
                            borderRadius : "5px", 
                            paddingRight: "7px", 
                            paddingBottom:"8px"
                        }}
                    spacing={1} 
                    direction="column" 
                    justifyContent="space-between" 
                    alignItems="center"
                >
                    <Grid container item xs={12}>
                            <TextField fullWidth type="password" id="outlined-password-input" label="Password" variant="outlined"
                                onChange={(e) => handleFormInputs(e, "password")} onKeyPress={handleEnterKeyPress} />
                    </Grid>
                    <Grid container item xs={12}>
                        <TextField fullWidth type="password" id="outlined-password-input" label="Ripeti Password" variant="outlined"
                            onChange={(e) => handleFormInputs(e, "repeatPassword")} onKeyPress={handleEnterKeyPress} />
                    </Grid>
                    <Grid container item xs={12}>
                        <Button variant="contained" loading={loading.toString()} onClick={setUpNewPassword} fullWidth
                            style={{backgroundColor: colorSetter(), color: "white",fontSize: "14px",letterSpacing: "1px", marginTop: "20px"}}
                        >
                            <b>CONFERMA</b>
                        </Button>
                    </Grid>
                </Grid>
                </div>
            }

            <div className="wrapper">
                <div className="slideshows">
                    <div className="slideshow slideshow--hero">
                        <div className="slides">
                            <div className="slide slide1"></div>
                            <div className="slide slide2"></div>
                            <div className="slide slide3"></div>
                        </div>
                    </div>
                    <div className="slideshow slideshow--contrast slideshow--contrast--before">
                        <div className="slides">
                            <div className="slide slide1"></div>
                            <div className="slide slide2"></div>
                            <div className="slide slide3"></div>
                        </div>
                    </div>
                    <div className="slideshow slideshow--contrast slideshow--contrast--after">
                        <div className="slides">
                            <div className="slide slide1"></div>
                            <div className="slide slide2"></div>
                            <div className="slide slide3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LoginPage;